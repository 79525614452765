<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="SEARVICE DETAILS"
          :breadcrumb="[
             {
            label: 'Dashboard',
            },
             { label: 'Packages & Services' },
             {
              label: 'Services',
            },
            { label: 'Service View' }
          ]"
        >
        </banner>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-7 col-md-7 col-sm-7"  style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card "
              style="
                height: 100%;
                
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #f21300; font-weight: bolder">Service Details</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #f21300; font-weight: bolder">Service Details</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                  


                    <span v-if="serviceInfo.logo">
                                <img
                                  style="width: 100px; height: 100px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/service-icons/${serviceInfo.id}/thumbs/${serviceInfo.logo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 100px; height: 100px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>



                    <br />




                    <span style="color: #00364f">
                      <button
                        type="button"
                        class="btn text-white btn-sm radius mt-1"
                        style="
                          background-color: green;
                          padding: 5px 15px 5px 15px;
                          box-shadow: 0px 0px 10px gray;
                        "
                      >
                        <b>{{ serviceInfo.status }}</b>
                      </button>
                    </span>
                  </div>

                  <div class="col-xl-10 col-md-10 col-lg-10 col-sm-12">
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span>{{ serviceInfo.code }}</span>
                    </div>

                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Service Name : </b>
                      <span>{{ serviceInfo.name }}</span>
                    </div>

                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Min Member : </b>
                      <span>{{ serviceInfo.minMember }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Max Member : </b>
                      <span>{{ serviceInfo.maxMember }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Min Capital : </b>
                      <span>{{ serviceInfo.minCapital }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Super Admin Fees : </b>
                      <span>{{ serviceInfo.superAdminFee }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Super Admin Fees Gst Required : </b>
                      <span v-if="serviceInfo.superAdminFeeGstRequired ==  'true'">Yes</span>
                      <span v-else>No</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b> Admin Fees : </b>
                      <span>{{ serviceInfo.adminFee }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b> Admin Fees Gst Required : </b>
                      <!-- <span>{{ serviceInfo.adminFeeGstRequired }}</span> -->
                      <span v-if="serviceInfo.adminFeeGstRequired ==  'true'">Yes</span>
                      <span v-else>No</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b> Government Fees Type: </b>
                      <span>{{ serviceInfo.gvtFeeType }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Government Fees : </b>
                      <span v-if="serviceInfo.gvtFeeType == 'dynamic'">
                        <button
                          type="button"
                          class="btn btn-sm text-white"
                          style="background-color: #f21300"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <b>Click Now</b>
                        </button>
                      </span>
                      <span v-else>
                        {{ serviceInfo.governmentFee }}
                      </span>
                    </div>

                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Fees : </b>
                      <span>{{ serviceInfo.professionalFee }}</span>
                    </div>
                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Gst Required : </b>
                      <!-- <span>{{ serviceInfo.professionalGstRequired }}</span> -->

                      <span v-if="serviceInfo.professionalGstRequired ==  'true'">Yes</span>
                      <span v-else>No</span>
                    </div>

                    <div class="text" style="margin-top: 5px">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Remark : </b>
                      <span>{{ serviceInfo.remark }}</span>
                    </div>
                  </div>

                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                  <span v-if="serviceInfo.logo">
                                <img
                                  style="width: 100px; height: 100px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/service-icons/${serviceInfo.id}/thumbs/${serviceInfo.logo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 100px; height: 100px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>
                    <span style="width: 40%">
                      <p
                        v-if="serviceInfo.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card "
              style="height: 100%"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4 class="text-start">
                      <b style="color: #f21300; font-weight: bolder">Module  Details</b>
                    </h4>
                    <ul class="list-group list-group-flush">
                      <li
                        class="list-group-item text-white"
                        style="background-color: #00364f"
                      >
                        <div class="row">
                          <div class="col-1 text-start" style="margin-left: -10px">
                            <b>+</b>
                          </div>
                          <div class="col-8 text-start"><b>Module Name </b></div>
                          <div class="col-3 text-end">
                            <b>Amount</b>
                          </div>
                        </div>
                      </li>
                      <li
                        class="list-group-item"
                        v-for="(moduleAddon, index) in serviceAddons"
                        :key="index"
                      >
                        <div class="row text">
                          <div class="col-1 text-start" style="margin-left: -10px">
                            <img
                              style="width: 8px"
                              src="/assets/image/record.png"
                              alt=""
                            />
                          </div>
                          <div class="col-8 text-start">
                            <b v-if="moduleAddon.service_module">{{
                              moduleAddon.service_module.moduleName
                            }}</b>
                          </div>
                          <div class="col-3 text-end">
                            <span v-if="moduleAddon">{{ moduleAddon.amt }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4 class="text-start">
                      <b style="color: #f21300; font-weight: bolder"
                        >Service Steps Information</b
                      >
                    </h4>

                    <ul class="list-group list-group-flush">
                      <li
                        class="list-group-item text-white"
                        style="background-color: #00364f"
                      >
                        <div class="row">
                          <div class="col-1 text-start" style="margin-left: -10px">
                            <b>+</b>
                          </div>
                          <div class="col-11 text-start"><b>Step Name </b></div>
                        </div>
                      </li>
                      <li
                        class="list-group-item"
                        v-for="(stepinfo, index) in serviceSteps"
                        :key="index"
                      >
                        <div class="row text">
                          <div class="col-1 text-start" style="margin-left: -10px">
                            <img
                              style="width: 8px"
                              src="/assets/image/record.png"
                              alt=""
                            />
                          </div>
                          <div class="col-11 text-start">
                            <b>{{ stepinfo.step.title }}</b>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Government Fees</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" style="color: #00364f">
            <div class="col-lg-4 col-xl-4 col-md-4 col-12 text" style="padding: 5px 5px 5px 5px">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault3"
                  data-bs-toggle="collapse"
                  href="#stateFee"
                  role="button"
                  aria-expanded="false"
                  aria-controls="stateFee"
                />
                <label class="flexCheckDefault3">
                  <b> State Wise</b>
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-12 text" style="padding: 5px 5px 5px 5px">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault1"
                  data-bs-toggle="collapse"
                  href="#memberFee"
                  role="button"
                  aria-expanded="false"
                  aria-controls="memberFee"
                />
                <label class="flexCheckDefault1">
                  <b>Member Wise</b>
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-12 text" style="padding: 5px 5px 5px 5px">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault2"
                  data-bs-toggle="collapse"
                  href="#otherFees"
                  role="button"
                  aria-expanded="false"
                  aria-controls="otherFees"
                />
                <label class="flexCheckDefault2">
                  <b> Other Fees</b>
                </label>
              </div>
            </div>
          </div>
          <div class="row collapse" id="stateFee">
            <div class="col-12">
              <h5 style="color: #f21300">
                <b>State Wise Fees</b>
              </h5>
              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm">
                  <tr class="text tr-head table-wrapper-scroll-y text-white">
                    <th>+</th>
                    <th>State Name</th>
                    <th>1</th>
                    <th>5</th>
                    <th>10</th>
                    <th>15</th>
                  </tr>

                  <tbody>
                    <tr
                      style="color: #00364f"
                      v-for="(state, index) in stateGvtFee.slice(start, end)"
                      :key="index"
                    >
                      <td>
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                      </td>

                      <td>
                        <b>{{ state.name }}</b>
                      </td>
                      <td>{{ valueChekerStateCharges(state.service_charges, "1") }}</td>
                      <td>{{ valueChekerStateCharges(state.service_charges, "5") }}</td>
                      <td>{{ valueChekerStateCharges(state.service_charges, "10") }}</td>
                      <td>{{ valueChekerStateCharges(state.service_charges, "15") }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-end">
                  <button
                    type="button"
                    class="btn btn-sm text-white mt-1"
                    @click="lastFiveState(start, end)"
                    style="background-color: #f21300"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm text-white mt-1"
                    @click="nextFiveState(start, end)"
                    style="background-color: #f21300; margin-left: 5px"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row collapse" id="memberFee">
            <div class="col-12">
              <h5 style="color: #f21300">
                <b>Member Wise Fees</b>
              </h5>

              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm">
                  <tr class="text tr-head table-wrapper-scroll-y text-white">
                    <th>+</th>
                    <th>Title</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                  </tr>

                  <tbody>
                    <tr
                      style="color: #00364f"
                      v-for="(memberAddon, index) in memberGvtFee"
                      :key="index"
                    >
                      <td>
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                      </td>

                      <td>
                        <b>{{ memberAddon.title }}</b>
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "1")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "2")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "3")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "4")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "5")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "6")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "7")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "8")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "9")
                        }}
                      </td>
                      <td>
                        {{
                          valueCheckerMemberFees(memberAddon.service_member_addons, "10")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row collapse" id="otherFees">
            <div class="col-12 mt-1">
              <h5 style="color: #f21300">
                <b>Other Fees</b>
              </h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item text-white" style="background-color: #00364f">
                  <div class="row">
                    <div class="col-1 text-start" style="margin-left: -10px">
                      <b>+</b>
                    </div>
                    <div class="col-8 text-start"><b>Perticuler </b></div>
                    <div class="col-3 text-end">
                      <b>Amount</b>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-for="(otherFee, index) in otherFeeGvt"
                  :key="index"
                >
                  <div class="row text">
                    <div class="col-1 text-start" style="margin-left: -10px">
                      <img style="width: 8px" src="/assets/image/record.png" alt="" />
                    </div>
                    <div class="col-8 text-start">
                      <b v-if="otherFee">{{ otherFee.title }}</b>
                    </div>
                    <div class="col-3 text-end">
                      <span v-if="otherFee">{{ otherFee.amt }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: red"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "ServiceDetails Profile",

  components: {
    Banner,
  },
  data() {
    return {
      start: 0,
      end: 5,
      serviceInfo: {},
      serviceSteps: {},
      serviceAddons: {},
      otherFeeGvt: [],
      stateGvtFee: [],
      memberGvtFee: [],
    };
  },
  methods: {
    nextFiveState(start, end) {
      // this.start = start + 5
      console.log(end);
      this.end = start + this.stateGvtFee.length;
    },
    lastFiveState(start, end) {
      console.log(end);
      console.log(start);
      this.start = 0;
      this.end = 5;
    },

    loadServiceInfo() {
      this.$axios
        .get(`superadmin/service/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.serviceInfo = res.data.data;
        });
    },

    loadServiceSteps() {
      this.$axios
        .get(`superadmin/servicesteps?service_id=${this.$route.params.id}&orderBy=priority`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.serviceSteps = res.data.data.data;
        });
    },
    loadServiceAddons() {
      this.$axios
        .get(
          `superadmin/servicemodulecharge?all=all&service_id=${this.$route.params.id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(res.data.data);

          this.serviceAddons = res.data.data;
        });
    },
    loadServiceOtherGvtFee() {
      this.$axios
        .get(`superadmin/serviceotherexpense?service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.otherFeeGvt = res.data.data.data;
        });
    },
    loadServiceStateGvtFee() {
      this.$axios
        .get(
          `superadmin/stateservicecharges?all=all&service_id=${this.$route.params.id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.stateGvtFee = res.data.data;
        });
    },
    valueChekerStateCharges(serviceCharges, authrizationCapital) {
      $("input").text("");
      console.log(authrizationCapital);
      console.log(serviceCharges);
      for (let i = 0; i < serviceCharges.length; i++) {
        if (serviceCharges[i].authrizationCapital == authrizationCapital) {
          return serviceCharges[i].governmentFee;
        }
      }
    },
    loadServiceStMemberGvtFee() {
      this.$axios
        .get(`superadmin/addonmemberservice?service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log('--------------------------')
          // console.log(res.data.data.data)
          // console.log('--------------------------')
          this.memberGvtFee = res.data.data.data;
        });
    },
    valueCheckerMemberFees(membercharges, members) {
      console.log("membercharges : " + membercharges + "members" + members);
      for (let i = 0; i < membercharges.length; i++) {
        if (membercharges[i].members == members) {
          return membercharges[i].amt;
        }
      }
    },
    // member wise end
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadServiceInfo();
      this.loadServiceSteps();
      this.loadServiceAddons();
      this.loadServiceOtherGvtFee();
      this.loadServiceStateGvtFee();
      this.loadServiceStMemberGvtFee();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");


.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #00364f;
}

.table-scroll {
  overflow-y: auto;
}

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
</style>
